import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import users from './users';
import timekeeper from './timekeeper';

export default combineReducers({
  ...auth,
  ...users,
  ...timekeeper,
});
