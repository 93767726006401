import { createSlice } from '@reduxjs/toolkit';

import { formatDate } from 'src/utils/get-time';

const currentDateSlice = createSlice({
  name: 'currentDate',
  initialState: formatDate(new Date()),
  reducers: {
    setCurrentDate: (state, { payload }) => {
      return payload;
    },
  },
});

const datesSlice = createSlice({
  name: 'dates',
  initialState: [],
  reducers: {
    setDates: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setCurrentDate } = currentDateSlice.actions;
export const { setDates } = datesSlice.actions;

export default {
  currentDate: currentDateSlice.reducer,
  dates: datesSlice.reducer,
};
