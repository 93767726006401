import gql from 'graphql-tag';

export const ME = gql`
  query ME {
    me {
      id
      email
      name
      image
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS {
    users {
      id
      email
      name
      phone
      position
      image
    }
    totalUsers
  }
`;

export const TOTAL_USERS = gql`
  query TOTAL_USERS {
    totalUsers
  }
`;
